<template>
  <div class="details-page__panel attr-panel-panel">
    <div class="hotel-detail-top-ratings-wrapper">
      <div v-if="getReviewCount" class="hotel-ratings-wrapper">
        <div class="rating-box fs-16 fw-600 inter-font-family">{{ getReviewScore }}</div>
        <div class="rating-overview">
          <div>{{getReviewTitle}}</div>
          <div>{{getReviewCount}} Reviews</div>
        </div>
      </div>

      <div class="hotel-star-rating" :title="`${getRating}-star hotel`">
        <v-icon
          v-for="index in getRating"
          :key="index"
          color="orange"
          class="pe-1"
          small
        >
          mdi-star
        </v-icon>

        <span class="card__star_rating nunito-font-family fs-16">{{ getRating }} star hotel</span>
      </div>
    </div>

    <h1 class="details-page__title" data-test="details-title">{{ getName }}</h1>
    <div class="address-points-wrapper">
      <div class="location-address">{{ getHotelAddress }}</div>
      <div v-if="getPointsLabel()" class="hotel-loyalty-points">
        <span class="inter-font-family fs-16 fw-700">
          <v-icon color="#A96500" small> mdi-star </v-icon> {{ getPointsLabel() }}
        </span>
      </div>
    </div>

    <div v-if="getDescription || getHotelDetailsInfo.hotelier_welcome_message">
      <div class="details-page__text" :class="{ 'text-full': showAll }">
        <p v-if="getDescription">
          {{ getDescription }}
        </p>
        <p v-if="getHotelDetailsInfo.hotelier_welcome_message">
          {{ getHotelDetailsInfo.hotelier_welcome_message }}
        </p>
      </div>
    </div>

    <div class="hotel-timings" v-if="getHotelCheckinPolicies() != undefined && getHotelCheckinPolicies().length">
      <p class="inter-font-family fw-400 fs-12" v-for="item in getHotelCheckinPolicies()" :key="item.id">
        <v-icon small>{{item.icon}}</v-icon>
        {{item.title}}
      </p>
    </div>

  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { logMixPanelEvent, mixPanelEvent } from '../../../utils/mixpanel';

export default {
  name: 'AttrsPanel',
  data: () => ({
    showAll: false
  }),
  computed: {
    ...mapGetters('details', [
      'getHotelDetailsInfo',
      'getName',
      'getRating',
      'getChainCode',
      'getReviewTitle',
      'getReviewCount',
      'getReviewScore',
      'getDescription',
      'getHotelAddress',
      'getCity',
      'getHotelMixPanelOptions',
      'getLabel',
      'getHotelRoomsAvailable'
    ]),
    ...mapGetters('user', ['userId', 'activeTripId', 'getLocationCoordinates']),
    ...mapGetters('hotelDetails', {
      getHotelPolicies: 'getHotelPolicies',
    }),
  },
  methods: {
    showMore() {
      logMixPanelEvent(
        mixPanelEvent.hotelEvents.hotelDetailEvents.detailsShowMore,
        {
          userId: this.userId,
          activeTripId: this.activeTripId,
          hotel: this.getHotelMixPanelOptions,
          distinct_id: this.userId
        },
        this.getLocationCoordinates
      );
      this.showAll = !this.showAll;
    },
    getPointsLabel() {
      switch (this.getChainCode) {
        case -5:
          return 'Earn Hilton Honors points';
        case -6:
          return 'Earn World of Hyatt points';
        case -7:
          return 'Earn IHG Rewards points';
        case -9:
          return 'Earn Marriott Bonvoy points';
        case -1:
          return 'Earn Accor Live Limitless points';
        default:
          return '';
      }
    },
    getHotelCheckinPolicies() {
      if (!this.getHotelRoomsAvailable.length) {
        return [];
      }

      const policyIcons = {
        'Check-in': 'mdi-clock-outline',
        'Check-out': 'mdi-clock-outline'
      };

      const checkinPolicies = this.getHotelRoomsAvailable[0].rooms[0].rateInfo.policies;
      if (checkinPolicies != undefined) {
        return [
          ...checkinPolicies
            .map(item => {
              return {
                icon: policyIcons[item.type],
                title: item.text
              };
            })
            .filter(item => item.icon)
        ];
        }
    }
  }
};
</script>
<style>
.location-address {
  font-weight: 600;
  font-size: 14px;
}
.attr-panel-panel {
  margin-bottom: 40px !important;
}
.hotel-loyalty-points {
  height: 40px;
  line-height: 24px;
  padding: 8px 16px 8px 16px;
  display: inline-block;
  border-radius: 8px;
  color: #A96500;
  background-color: #FF99001A;
}
.hotel-timings {
  margin: 20px 0 0px 0;
  p {
    margin-bottom: 10px;
    line-height: 16px;
  }
}

.hotel-detail-top-ratings-wrapper, .address-points-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.hotel-ratings-wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 8px;

  .rating-box {
    width: 36px;
    height: 36px;
    padding: 4px;
    line-height: 28px;
    background: #5B8CB1;
    color: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rating-overview {
    flex: 1;
    padding-left: 12px;
    font-size: 12px;
  }
}
</style>
